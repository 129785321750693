import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent implements OnInit {

  constructor() { }

  imageObject: any;

  ngOnInit() {
    this.imageObject= [{
      image: '../../assets/images/slider_new/studio1.jpg',
      thumbImage: '../../assets/images/slider_new/studio1.jpg',
      title: 'Studio'
    }, {
      image: '../../assets/images/slider_new/studio2.jpg', // Support base64 image
      thumbImage: '../../assets/images/slider_new/studio2.jpg', // Support base64 image
      title: 'Behandlungstisch' //Optional: You can use this key if want to show image with title
    }, {
      image: '../../assets/images/slider_new/studio3.jpg',
      thumbImage: '../../assets/images/slider_new/studio3.jpg',
      title: 'Deko'
    },
    {
      image: '../../assets/images/slider_new/gutschein.jpg',
      thumbImage: '../../assets/images/slider_new/gutschein.jpg',
      title: 'Gutschein'
    }
    ];
  
  }

  
}
