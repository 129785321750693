<!--<div class="row mt-5">
    <div class="col-md-9 mx-auto">
        <h2 class="text-left">Google Map</h2>
        <div class="card mt-3">
            <div class="card-body">
                <div #mapRef style="width:100%;height:400px"></div>
            </div>
        </div>
    </div>
</div>-->

<h3>My Google Maps Demo</h3>
<!--The div element for the map -->
<div id="map"></div>
<script>
// Initialize and add the map
function initMap() {
        
    console.log('doing stuff')
    // The location of Uluru
    var uluru = {lat: -25.344, lng: 131.036};
    // The map, centered at Uluru
    var map = new google.maps.Map(
    document.getElementById('map'), {zoom: 4, center: uluru});
    // The marker, positioned at Uluru
    var marker = new google.maps.Marker({position: uluru, map: map});
}
</script>
<!--Load the API from the specified URL
* The async attribute allows the browser to render the page while the API loads
* The key parameter will contain your own API key (which is not needed for this tutorial)
* The callback parameter executes the initMap() function
-->
<script async defer src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDkqGYh5qnQ6br8a073czaDByX_VVrtL8o&callback=initMap">
console.log('called something')
</script>
