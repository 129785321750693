import { NgModule } from "@angular/core";
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import {
  MatListModule,
  MatTabsModule,
  MatCardModule,
  MatGridListModule
} from '@angular/material';

@NgModule({
  imports: [MatToolbarModule,
            MatIconModule,
            MatSidenavModule,
            MatListModule,
            MatButtonModule,
            MatTabsModule,
            MatCardModule,
            MatGridListModule],
  exports: [MatToolbarModule,
            MatIconModule,
            MatSidenavModule,
            MatListModule,
            MatButtonModule,
            MatTabsModule,
            MatCardModule,
            MatGridListModule]
})

export class MaterialModule {}
