<div class="navtabs-container">

  <nav mat-tab-nav-bar class="slider">
    <span class="toolbar-flex"></span>
    <a mat-tab-link
       *ngFor="let link of navLinks"
       [routerLink]="link.path"
       routerLinkActive #rla="routerLinkActive"
       [routerLinkActiveOptions]="{exact:true}"
       [active]="rla.isActive">
      {{link.label}}
    </a>
    <span class="toolbar-flex"></span>
  </nav>
</div>

<!--<router-outlet></router-outlet>-->



<!-- <nav mat-tab-nav-bar>

  <a mat-tab-link
     routerLink="/"
     routerLinkActive #home="routerLinkActive"
     [active]="home.isActive">
    Home
  </a>

  <a mat-tab-link
     routerLink="/treatments"
     routerLinkActive #treatments="routerLinkActive"
     [active]="treatments.isActive">
    Treatments
  </a>

</nav>


<router-outlet></router-outlet>

-->


 <!--<div class="navtabs-container">
  <nav mat-tab-nav-bar mat-tab-nav-bar>

    <a mat-tab-link routerLink="/"
      routerLinkActive #home="routerLinkActive"
      [active]="home.isActive">
      Home
    </a>

    <a mat-tab-link routerLink="/treatments"
      routerLinkActive #treatments="routerLinkActive"
      [active]="treatments.isActive">
      Treatments
    </a>



  </nav>
</div>
<router-outlet></router-outlet> -->



<!--
 <nav mat-tab-nav-bar>
  <span class="toolbar-flex"></span>
  <mat-tab-group>
    <mat-tab label="Angebot"></mat-tab>
    <mat-tab label="Über mich"></mat-tab>
    <mat-tab label="Bilder"></mat-tab>
  </mat-tab-group>
  <span class="toolbar-flex"></span>
</nav>
-->
