import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TreatmentsComponent } from './treatments/treatments.component';
import { HomeComponent } from './home/home.component';
import { AngebotComponent } from './angebot/angebot.component';
import { HighlightsComponent } from './highlights/highlights.component';
import { InformationComponent } from './information/information.component';


const routes: Routes = [
  { path: '', component: AngebotComponent},
  { path: 'angebot', component: AngebotComponent},
  { path: 'highlights', component: HighlightsComponent},
  { path: 'info', component: InformationComponent},
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
