<mat-sidenav-container>
  <!--
  <mat-sidenav #sidenav>
    <app-sidenav-list (closeSideNavigation)="sidenav.close()"></app-sidenav-list>
  </mat-sidenav>
-->
  <mat-sidenav-content>
    <app-header></app-header>
    <app-navtabs></app-navtabs>


    <router-outlet></router-outlet>


  </mat-sidenav-content>


</mat-sidenav-container>

<!--<footer>
    <a href="https://www.bru.lu">Made by CoolName</a>
</footer>-->


<footer class="Footer_outer">
  <mat-toolbar color="accent">
    <!--
    <div class="row">
      <div class="">
        © 2020
      </div>
      <div class="col l10 m1">
        <a href="https://www.bru.lu">Made by CoolName</a>
      </div>
    </div>-->
    <div class="Footer_inner_left">
      © 2020 
    </div>
    <div class="Footer_inner_right">
      <!-- <a href="https://www.bru.lu" color="black">Made by CoolName</a> -->
    </div>

  </mat-toolbar>
</footer>