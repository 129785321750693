<mat-card class="address-card">
    <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>Studio zum Rosenengel</mat-card-title>
        <mat-card-subtitle>Informationen</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <mat-list>
            <mat-list-item>
                <mat-icon matListIcon>alternate_email</mat-icon>
                <h3 matLine> Email </h3>
                <p matLine>
                    <span>  <a href="mailto:schaefer.monica@bluewin.ch?subject=SweetWords">
                        schaefer.monica@bluewin.ch</a>  </span>
                   
                </p>
            </mat-list-item>

            <mat-list-item>
                <mat-icon matListIcon>phone</mat-icon>
                <h3 matLine> Telefon / Whatsapp </h3>
                <p matLine>
                    <span> 079 672 55 57 </span>
                </p>
            </mat-list-item>

            <mat-list-item>
                <mat-icon matListIcon>house</mat-icon>
                <h3 matLine> Adresse </h3>
                <p matLine>
                    <span> Oberdorfstrasse 10, Hochwald </span>
                </p>
            </mat-list-item>

            <mat-list-item>
                <mat-icon matListIcon>streetview</mat-icon>
                <h3 matLine> Wegbeschreibung </h3>
                <br>
                <p matLine>
                    <a href="https://goo.gl/maps/ZXbaPeeWFepMtK8ZA" title="Wegbeschreibung">Google Maps</a>

                </p>
            </mat-list-item>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2697.7607690187087!2d7.638024715748078!3d47.455602606389625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4791c8e424f3b9cf%3A0x22c7692c59bad089!2sOberdorfstrasse%2010%2C%204146%20Hochwald!5e0!3m2!1sde!2sch!4v1582211623043!5m2!1sde!2sch" frameborder="0" style="border:0;" allowfullscreen=""></iframe>

            <mat-list-item>
                <mat-icon matListIcon>directions_car</mat-icon>
                <h3 matLine> Kundenparkplatz vorhanden </h3>
                <br>
                <p matLine>
                    <span> 10 Autominuten von Dornach/Aesch/Arlesheim <br>
                        20 Autominuten von Basel</span>
                </p>
            </mat-list-item>

            <mat-list-item>
                <mat-icon matListIcon>directions_bus</mat-icon>
                <h3 matLine> Anreise mit ÖV </h3>
                <br>
                <p matLine>
                    <span> Busstation 100 m entfernt</span>
                </p>
            </mat-list-item>

            <mat-list-item>
                <mat-icon matListIcon>access_time</mat-icon>
                <h3 matLine> Öffnungszeiten </h3>
                <br>
                <p matLine>
                    <span>
                        <!-- Montag 08:00 - 18:00 Uhr <br>
                        Dienstag 08:00 - 18:00 Uhr <br> -->
                        08.00 – 18.00 Uhr <br>
                        Jeden 3. Samstag im Monat 
                    </span>
                </p>
            </mat-list-item>

            <mat-list-item>
                <mat-icon matListIcon>credit_card_off</mat-icon>
                <h3 matLine> Bezahlung </h3>
                <br>
                <p matLine>
                    <span>Nur Barzahlung möglich.
                    </span>
                </p>
            </mat-list-item>

        </mat-list>
    </mat-card-content>
    <!--<mat-card-actions>
        <button mat-button>LIKE</button>
        <button mat-button>SHARE</button>
    </mat-card-actions>-->
</mat-card>

<!--<app-map></app-map>-->