<div class="row">
  <div class="column left">
    <h2>Column 1</h2>
    <p>Some text..</p>
  </div>
  <div class="column middle" style="background-color:#ccc;">
    <h2>Column 2</h2>
    <p>Some text..</p>
  </div>
  <div class="column right">
    <h2>Column 3</h2>
    <p>Some text..</p>
  </div>
</div>