import { Component, OnInit } from '@angular/core';

//import angebote from '../angebote.json';
import angebote_fuss from '../angebote_fuss.json';
import angebote_hand from '../angebote_hand.json';
import angebote_gesicht from '../angebote_gesicht.json';
import angebote_divers from '../angebote_divers.json';
import angebote_haar from '../angebote_haarentfernung.json';

@Component({
  selector: 'app-angebot',
  templateUrl: './angebot.component.html',
  styleUrls: ['./angebot.component.css']
})
export class AngebotComponent implements OnInit {

  constructor() {}

  angebote_fuss = angebote_fuss;
  angebote_hand = angebote_hand;
  angebote_gesicht = angebote_gesicht;
  angebote_divers = angebote_divers;
  angebote_haar = angebote_haar;

  ngOnInit() {
  }

}
