<div class="content">
  <div class="row">
    <div class="column left">

    </div>
    <div class="column middle">

      <div class="angebot-card">
        <mat-card class="angebot-card">


          <div class="profile">
            <br><br>
            <img class="prof-picture" src="../../assets/images/monica_new.jpg" style="width: 60%;">
            <h2>Monica Schäfer </h2>
            <h2>Für Hand und Fuss</h2>
            <br><br>
          </div>

          <mat-divider [inset]="true"></mat-divider>

          <div>
            <h2>Über mich</h2>
            <p>Ich möchte meinen lieben Kunden für einen tollen Preis regelmässig Freude an schönen und gepflegten Händen und Füssen ermöglichen. <br><br>
              Geboren bin ich am 31.12.74; seit 2002 mit meinem Mann verheiratet. Wir haben einen Sohn. Teilzeit arbeite ich als Kauffrau/medizinische Sekretärin im Spital. <br>
              Beide Tätigkeiten schätze ich unheimlich und möchte keine mehr missen. Hygiene und Diskretion sind für mich selbstverständlich. <br>
              Kurse im Bereich Nageldesignerin, Fusspflege, Augenbrauenkorrektur sowie Haarentfernung besucht.
          </div>

          <mat-divider [inset]="true"></mat-divider>

          <div>
            <h2>Studio</h2>
            <div class="slider_object">
              <ng-image-slider [images]="imageObject" [imageSize]="{width:280, height:450}" #nav></ng-image-slider>
            </div>
           
          </div>
        </mat-card>
        
      </div>

    </div>
    <div class="column space">
    </div>
    <div class="column right">
      <div class="address-box">
        <app-adresse></app-adresse>
      </div>

    </div>
    <div class="column rightmost">
    </div>
  </div>
</div>