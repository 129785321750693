<div class="content">
  <div class="row">
    <div class="column left">

    </div>
    <div class="column middle">
      <!-- <h3 matSubheader class="angebot-typ">NEU im Angebot</h3> -->

      

      <mat-list>

        <div *ngFor="let angebot of angebote_hand">
          <div class="angebot">
            <h3 matSubheader class="angebot-typ">{{angebot.Typ}}</h3>
            <mat-card class="special-angebot-card">

              <h4>Nagelverlängerung mit Gel-X-tention Tips</h4>
      
              <div class="image-container">
                <img class="boarder_pic studio-picture" src="../../assets/images/low/20200219_144409.jpg">
                <img class="boarder_pic studio-picture" src="../../assets/images/low/20200219_155629.jpg">
              </div>
              <h4>
                Neues Set Fr. 75<br>
                Auffüllen nach 3 - 4 Wochen Fr. 60
              </h4>
      
      
            </mat-card>
            <mat-card class="angebot-card">
              <mat-list-item *ngFor="let behandlung of angebot.Beschreibung; last as last">

                <mat-icon matListIcon>arrow_forward</mat-icon>
                <p matLine >{{behandlung.Behandlung}}</p>
                <p class="price"> {{behandlung.Preis}} </p>
                <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
              </mat-list-item>
            </mat-card>
          </div>
        </div>


      </mat-list>

      

      <mat-list>

        <div *ngFor="let angebot of angebote_fuss">
          <div class="angebot">
            <h3 matSubheader class="angebot-typ">{{angebot.Typ}}</h3>
            <mat-card class="special-angebot-card">

              <!-- <h4>Pediküre mit French</h4> -->
      
              <div class="image-container">
                <img class="boarder_pic portrait-picture" src="../../assets/images/low/fuss_tattoo_low.jpg">
              </div>
              
      
      
            </mat-card>
            <mat-card class="angebot-card">
              <mat-list-item *ngFor="let behandlung of angebot.Beschreibung; last as last">

                <mat-icon matListIcon>arrow_forward</mat-icon>
                <p matLine >{{behandlung.Behandlung}}</p>
                <p class="price"> {{behandlung.Preis}} </p>
                <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
              </mat-list-item>
            </mat-card>
          </div>
        </div>

      </mat-list>

      <mat-list>

        <div *ngFor="let angebot of angebote_divers">
          <div class="angebot">
            <h3 matSubheader class="angebot-typ">{{angebot.Typ}}</h3>
            
            <mat-card class="angebot-card">
              <mat-list-item *ngFor="let behandlung of angebot.Beschreibung; last as last">

                <mat-icon matListIcon>arrow_forward</mat-icon>
                <p matLine >{{behandlung.Behandlung}}</p>
                <p class="price"> {{behandlung.Preis}} </p>
                <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
              </mat-list-item>
            </mat-card>
          </div>
        </div>

      </mat-list>

      <mat-list>

        <div *ngFor="let angebot of angebote_gesicht">
          <div class="angebot">
            <h3 matSubheader class="angebot-typ">{{angebot.Typ}}</h3>
            <mat-card class="angebot-card">
              <mat-list-item *ngFor="let behandlung of angebot.Beschreibung; last as last">

                <mat-icon matListIcon>arrow_forward</mat-icon>
                <p matLine >{{behandlung.Behandlung}}</p>
                <p class="price"> {{behandlung.Preis}} </p>
                <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
              </mat-list-item>
            </mat-card>
          </div>
        </div>


      </mat-list>

      <mat-list>

        <div *ngFor="let angebot of angebote_haar">
          <div class="angebot">
            <h3 matSubheader class="angebot-typ">{{angebot.Typ}}</h3>
            
            <mat-card class="angebot-card">
              <mat-list-item *ngFor="let behandlung of angebot.Beschreibung; last as last">

                <mat-icon matListIcon>arrow_forward</mat-icon>
                <p matLine >{{behandlung.Behandlung}}</p>
                <p class="price"> {{behandlung.Preis}} </p>
                <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
              </mat-list-item>
            </mat-card>
          </div>
        </div>

      </mat-list>

      <div>
        <h3>Für alle Behandlungen sind Gutscheine erhältlich</h3>
        <h3>Termine können entweder telefonisch oder per Whatsapp vereinbart werden</h3>
      </div>

    </div>
    <div class="column space">
    </div>
    <div class="column right">
      <div class="address-box">
        <app-adresse></app-adresse>
      </div>
    </div>
    <div class="column rightmost">
    </div>
  </div>
</div>