/*
https://medium.com/angular-in-depth/google-maps-is-now-an-angular-component-821ec61d2a0
API KEY: AIzaSyDkqGYh5qnQ6br8a073czaDByX_VVrtL8o

Don't forget to restrict the key!
*/

import { AfterViewInit, Component, ElementRef, ViewChild, OnInit } from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  @ViewChild('mapRef', { static: true }) mapElement: ElementRef;

  constructor() { }

  ngOnInit() {
    //this.renderMap();
  }

  /*
  loadMap = () => {

    console.log('load map');
    var map = new window.google.maps.Map(this.mapElement.nativeElement, {
      center: { lat: 24.5373, lng: 81.3042 },
      zoom: 8
    });

    var marker = new window['google'].maps.Marker({
      position: { lat: 24.5373, lng: 81.3042 },
      map: map,
      title: 'Hello World!',
      draggable: true,
      animation: window['google'].maps.Animation.DROP,
    });

    var contentString = '<div id="content">' +
      '<div id="siteNotice">' +
      '</div>' +
      '<h3 id="thirdHeading" class="thirdHeading">W3path.com</h3>' +
      '<div id="bodyContent">' +
      '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>' +
      '</div>' +
      '</div>';

    var infowindow = new window['google'].maps.InfoWindow({
      content: contentString
    });

    marker.addListener('click', function () {
      infowindow.open(map, marker);
    });
  }


  renderMap() {

    console.log('rendermap is called');

    this.loadMap();

    window['initMap'] = () => {
      this.loadMap();
    }
    if (!window.document.getElementById('google-map-script')) {
      console.log('case 1');
      var s = window.document.createElement('script');
      s.id = 'google-map-script';
      s.type = 'text/javascript';
      //s.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDkqGYh5qnQ6br8a073czaDByX_VVrtL8o;callback=initMap';
      s.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDkqGYh5qnQ6br8a073czaDByX_VVrtL8o';

      window.document.body.appendChild(s);
    } else {
      console.log('case 2');
      this.loadMap();
    }
  }
*/
}
