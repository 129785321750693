import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-navtabs',
  templateUrl: './navtabs.component.html',
  styleUrls: ['./navtabs.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NavtabsComponent implements OnInit {

  constructor() { }

  navLinks: any;

  ngOnInit() {
    this.navLinks = [
      {path:'/angebot', label: 'Angebot'},
      {path:'/highlights', label: 'Highlights'},
      {path:'/info', label: 'Über mich'}

    ];
  }

}
