<div class="content">
    <div class="row">
        <div class="column left">

        </div>
        <div class="column middle">
            <div class="angebotsliste">
            <mat-card class="angebot-card">
                <h2>Einige Beispiele meiner Arbeit</h2>
                <div class="">
                    <figure class="studio-picture">
                        <img class="boarder_pic" src="../../assets/images/low/20200212_102848.jpg">
                        <figcaption>vorher</figcaption>
                    </figure>
                    <figure class="studio-picture">
                        <img class="boarder_pic" src="../../assets/images/low/20200212_115411.jpg">
                        <figcaption>nachher</figcaption>
                    </figure>
                    
                </div>
                <br>
                

                <div class="image-container">
                    <figure  class="studio-picture">
                        <img class="boarder_pic" src="../../assets/images/low/20200208_120332.jpg">
                        <figcaption>vorher</figcaption>
                    </figure>
                    
                    <figure class="studio-picture">
                        <img class="boarder_pic" src="../../assets/images/low/20200208_132644.jpg">
                        <figcaption>nachher</figcaption>
                    </figure>
                    
                    
                </div>
                <br>

                <div class="image-container">
                    <figure  class="studio-picture">
                        <img class="boarder_pic" src="../../assets/images/low/fuss_vorher.jpg">
                        <figcaption>vorher</figcaption>
                    </figure>
                    
                    <figure class="studio-picture">
                        <img class="boarder_pic" src="../../assets/images/low/fuss_nachher.jpg">
                        <figcaption>nachher</figcaption>
                    </figure>
                    
                    
                </div>
                <br>

                <h3 class="center_text">Modellage eines defekten Nagels vorher/nachher</h3>
                <div class="image-container">
                    <div>
                        <figure class="studio-picture">
                            <img class="boarder_pic studio-picture" src="../../assets/images/low/20200112_103043.jpg">
                            <figcaption>vorher</figcaption>
                        </figure>
                        <figure class="studio-picture">
                            <img class="boarder_pic studio-picture" src="../../assets/images/low/20200216_134846.jpg">
                            <figcaption>nachher</figcaption>
                        </figure>
                        
                        
                    </div>
                </div>
                <br>
                
                <h3 class="center_text">Naturnagelverstärkung</h3>
                <div class="image-container">
                    <div>
                        <figure class="studio-picture">
                            <img class="boarder_pic studio-picture" src="../../assets/images/low/Naturnagelverstärkung.jpg">
                        </figure>
                        
                        
                    </div>
                </div>
                <br>
            
                <h3>ALLE FOTOS SIND UNBEARBEITET und NICHT KASCHIERT</h3>

            </mat-card>
        </div>
        </div>
        <div class="column space">
        </div>
        <div class="column right">
            <div class="address-box">
                <app-adresse></app-adresse>
            </div>
            
        </div>
        <!--<div class="column rightmost">
        </div>-->
    </div>
</div>